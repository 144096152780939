ul.pagination {
  width: 227px;
  margin: 30px auto;
}
ul.pagination li.page-item.active a.page-link {
  background-color: #e3e7eb !important;
  border-color: #ced4da !important;
}
ul.pagination span.page-link {
  /* padding: 0.75rem 1rem; */
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #1d1d1d;
  font-weight: 900;
  font-size: 1rem;
}
ul.pagination a.page-link:hover {
  background-color: #f4f4f4;
}
ul.pagination a.page-link.page-details:hover {
  cursor: default;
  color: #1d1d1d;
  background-color: #fff;
}
ul.pagination a.page-link.page-details span {
  font-weight: 400;
  white-space: nowrap;
}

ul.pagination a.page-link.page-disabled {
  color: lightgrey;
}

ul.pagination a.page-link.page-disabled:hover {
  cursor: default;
  background-color: #fff;
}
