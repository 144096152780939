.sidebar-wrapper,
.navbar-fixed {
  -webkit-transition-property: top, bottom, width, transform;
  transition-property: top, bottom, width, transform;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease, ease;
  transition-timing-function: linear, linear, ease, ease;
  -webkit-overflow-scrolling: touch;
}
#main-wrapper {
  width: 1328px;
}
.bg-dark #main-wrapper {
  width: 100%;
}
.sideMenu-panel {
  max-width: 192px !important;
}
.sidebar {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  max-width: 192px;
  z-index: 3;
}

.main-panel {
  width: 1072px !important;
  padding-top: 64px;
  margin: 0 32px;
  flex: inherit !important;
}
.main-panel .container {
  max-width: 100% !important;
}
header {
  position: fixed;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
header nav {
  height: 64px;
}

.sidebar-mini .sidebar,
.sidebar-mini .sideMenu-panel {
  max-width: 56px !important;
}

.sidebar-mini .main-panel {
  width: 1208px !important;
}
.sidebar-mini .main-panel .container {
  width: 100% !important;
}
.sidebar-mini .sidebar .sidebar-wrapper p,
.sidebar-mini .sidebar .sidebar-wrapper .expand,
.sidebar-mini .sidebar .sidebar-wrapper .sub-menu {
  display: none;
}
.sidebar-mini .sidebar .sidebar-wrapper img {
  margin: 0;
}
.sidebar .sidebar-wrapper > .nav {
  margin-top: 20px;
}
.sidebar .sidebar-wrapper .nav li a {
  outline: none;
  cursor: pointer;
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}
.sidebar .nav > li > a {
  position: relative;
  display: flex;
  padding: 0px 16px 0 10px;
  margin: 0px;
  align-items: center;
  height: 38px;
}

.sidebar-mini .sidebar .nav > li > a,
.sidebar-mini .sidebar .nav > li > .expandMenu {
  padding: 0px 16px 0 10px;
}
.sidebar .nav img {
  float: left;
  margin-right: 8px;
  line-height: normal;
  height: 24px;
  width: 24px;
  text-align: center;
}
.sidebar .nav p {
  margin: 0;
  line-height: normal;
  letter-spacing: normal;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  white-space: nowrap;
  position: relative;
  color: #ffffff;
  transform: translate3d(0px, 0, 0);
  display: block;
  height: 19px;
  opacity: 1;
}
.sidebar .nav li.active .menuItems {
  font-weight: 600;
}
.sidebar .sidebar-wrapper {
  position: relative;
  display: flex;
  left: 0;
  bottom: 0;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: calc(100% - 350px);
  scrollbar-width: none;
}
.sidebar div::-webkit-scrollbar {
  display: none;
}

.sidebar-wrapper .nav li {
  width: 100%;
  border-left: 6px solid #1d1d1d;
  margin: 4px 0px;
  cursor: pointer;
}

.sidebar-wrapper .nav li.active {
  border-left: 6px solid #6dc4a7;
}
.sidebar-mini .sidebar-wrapper .nav li.active {
  border-left: 6px solid #6dc4a7;
}
.sidebar-wrapper .nav li .expand,
.menuWrapper li .expand {
  float: right;
  position: relative;
  width: 24px;
  height: 24px;
  margin-top: -30px;
  margin-right: 10px;
}
.sidebar-wrapper .nav li .expand img,
.menuWrapper li .expand img {
  position: absolute;
  width: 24px;
  height: 24px;
}

.expand img.rotate {
  transform: rotate(180deg);
}
.sub-menu.nav {
  background: #eeeeee;
  color: #242f33;
}
.sub-menu.nav li {
  border-bottom: 1px solid #dcdcde;
  width: 90%;
  margin: 0 auto;
  border-left: 0;
}
.sub-menu.nav li.active {
  border-left: 0;
}
.sub-menu.nav li.active p {
  font-weight: 600;
}
.sub-menu.nav li p {
  color: #242f33;
  padding: 4px 10px;
  font-weight: normal;
}

.expandMenu {
  display: flex;
  padding: 0px 16px 0 10px;
  margin: 0px;
  align-items: center;
  height: 38px;
}

.sub-menu.nav li a:hover,
.sub-menu.nav li p:hover {
  color: black;
  text-decoration: underline;
}

.logoContainer {
  height: 96px;
  padding: 24px 16px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.menuContainer {
  width: 100%;
  height: 39px;
  margin-top: 24px;
  margin-bottom: 15px;
  padding-left: 16px;
}

.menuContainer img {
  width: 26px;
  height: 26px;
}
.menuWrapper {
  border-top: 1px solid #4a4a4a;
  height: 175px !important;
  padding-top: 12px;
}

.menuWrapper .sub-menu.nav li {
  position: relative;
  display: flex;
  padding: 0px 16px;
  margin: 0px;
  align-items: center;
  height: 38px;
  cursor: pointer;
}
