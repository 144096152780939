body {
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.51px !important;
  line-height: 28px !important;
}

html,
body,
#root {
  height: 100%;
}
body,
.container {
  min-width: inherit;
}
h1 {
  font-size: 30px;
  font-weight: 600;
  color: #1d1d1d;
  letter-spacing: 0.25px;
}

h4 {
  color: #1d1d1d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

p {
  font-size: 14px;
  font-weight: normal;
}

@media (min-width: 1200px) {
  .container {
    width: 1100px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 1100px;
  }
}

.breadcrumb {
  background: none !important;
  margin-bottom: 0.4rem;
  margin-left: 0px;
}
.search-box .input-group-append .btn {
  background-color: #fff;
}

#search-box > div > div {
  height: 100%;
}

#search-box > div > div > input {
  height: 100%;
  padding: 0 !important;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
  background-color: inherit !important;
  line-height: 1.14;
  letter-spacing: 0.47px;
}

.btn {
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  /* padding: 14px 18px; */
  padding: 0.7rem 0.75rem;
  letter-spacing: 1.27px;
}

.btn-primary {
  background-color: #009688;
  border-color: #009688;
  border: 2px solid #009688;
}

.btn-primary:hover {
  background-color: #00897b;
  border-color: #00897b;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #00a897;
  border-color: #006c62;
}

.btn-secondary {
  background-color: inherit;
  border: 1px solid #009688;
  color: #009688;
}

.btn-secondary:hover {
  background-color: #009688;
  border: 1px solid #009688;
}

.btn-secondary:disabled {
  pointer-events: none;
  background-color: inherit;
  border-color: #009988;
  color: #009988;
  opacity: 0.5;
}
.btn-primary.disabled,
.btn-primary:disabled {
  opacity: 0.5;
  background-color: #009688 !important;
  border-color: #009688 !important;
}

a,
a:hover,
a:focus,
button,
button:hover,
button:focus,
input:focus,
.search-box input:focus {
  box-shadow: none;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-context {
  cursor: context-menu;
}
.search-auto .css-vj8t7z {
  border: none;
}

.grey {
  color: #7f7f7f;
}

.bg-grey-3 {
  background-color: #7f7f7f !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-15 {
  font-size: 15.8px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-59 {
  font-size: 59.7px;
}
.version {
  font-size: 8px;
  color: #666;
  margin-left: 10px;
  vertical-align: bottom;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 20px 10px 0;
  font-weight: 300;
  border: none;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  border: none;
  background-color: #ffffff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 38.5px;
  border-bottom-right-radius: 38.5px;
  z-index: 3;
  top: 22px;
  left: -27px;
  right: -86px;
  height: 300px;
  overflow-y: auto;
  padding-top: 0px;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar {
  display: none;
}

.react-autosuggest__container--open div::parent {
  border: 1px solid red;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px 10px 28px;
  text-align: left;
}
.react-autosuggest__suggestion:hover {
  background-color: #ddd;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

/*.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-bottom-left-radius: 0;
}

.input-group-append > .btn {
  border-bottom-right-radius: 0;
}*/

.dashboard-admin-text {
  width: max-content;
  height: max-content;
  background-color: #1d1d1d;
  color: #fafafa;
  border: 0px;
  padding-left: 0px;
  resize: none;
  font-family: sans-serif;
  font-size: 18px;
  text-align: justify;
}

.dashboard-admin-text:disabled {
  height: 75px;
  overflow: hidden;
}

:focus {
  outline: none;
}

.text-area-wrapper {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 7px 6px 1px 10px;
}
.text-area-wrapper-product {
  width: 510px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 7px 6px 1px 10px;
}

.text-underline {
  text-decoration: underline;
}

.product-edit-description-enabled {
  width: 7in;
  height: 2.6in;
  /* background-color: #fafafa; */
  color: black;
  border: 0px;
  resize: none;
  font-family: sans-serif;
  font-size: 18px;
  padding-right: 5px;
  padding-top: 8px;
  text-align: justify;
}

.product-edit-description-enabled::-webkit-scrollbar {
  width: 0.4em;
}
.product-edit-description-enabled::-webkit-scrollbar-track {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.product-edit-description-enabled::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 8px;
}

.dashboard-admin-text-enabled {
  width: 6in;
  height: 1.2in;
  /* background-color: #fafafa; */
  color: black;
  border: 0px;
  resize: none;
  font-family: sans-serif;
  font-size: 18px;
  padding-right: 5px;
  padding-top: 8px;
  text-align: justify;
}
:focus {
  outline: none;
}
.dashboard-admin-text-enabled::-webkit-scrollbar {
  width: 0.4em;
}
.dashboard-admin-text-enabled::-webkit-scrollbar-track {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.dashboard-admin-text-enabled::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 8px;
}

.dashboard-admin-text:disabled {
  -webkit-text-fill-color: #fafafa;
  -webkit-opacity: 1;
  color: #fafafa;
  opacity: 1;
}

.admin-message-content {
  text-align: justify;
}

.editIcon {
  cursor: pointer;
}

.edit-icon-product {
  cursor: pointer !important;
  /* padding-left: 10px;
  margin-right: -20px; */
  margin-top: -13px;
}

.text-area-style {
  padding-left: 15px;
}
.product-brief-edit {
  margin-right: -40px;
}

.reseller-dashboard-admin-message p {
  text-align: justify;
}

.input-forgot-pwd::placeholder {
  color: #bfbfbf;
  opacity: 1; /* Firefox */
}

.bg-dark-aquamarine {
  background-color: #006c62;
}

.autosuggest.input-group > .form-control:not(:last-child) {
  border-bottom-left-radius: 0;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.15em;
}

.ri-table .table-row {
  /* border-top: none; */
  border-top: 1px solid rgb(0, 0, 0, 0.1);
}

.ri-table .table-row :last-of-type {
  border-bottom: none;
}

.ri-table .table-row .thumbnail::after {
  content: '';
  background: url(../assets/images/icons/icon-accordion-arrow-black.svg) no-repeat center center;
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.ri-table .table-row .rotate-180deg::after {
  background: url(../assets/images/icons/icon-accordion-arrow-green.svg) no-repeat center center;
}

.ri-table .table-row .thumbnail span {
  background-color: #6a6a6a;
  display: block;
  height: 7px;
  width: 11px;
}

.ri-list-row-details {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.ri-list-row-details:hover {
  widows: 300px;
}

.ri-list-row-details > .collapse {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  display: block;
  border: none;
  min-height: 0;
}

.ri-list-row-details > .collapse.show {
  max-height: 1000px !important;
  transition: all 1s ease-in-out;
}

#orders .rounded {
  border-radius: 15px !important;
}

#orders .rounded-bottom {
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.error-icon {
  position: absolute;
  right: 18px;
  bottom: 34px;
}

.error-message {
  min-height: 22px;
}
.signin-error {
  right: 15px;
  top: 10px;
}

#chooseCustomer .bg-dark-aquamarine,
#chooseCustomer .customer-details,
#chooseReseller .bg-dark-aquamarine,
#chooseReseller .customer-details {
  height: 47px;
  padding-top: 10px;
}

#chooseCustomer .edit-details-modal .react-autosuggest__input {
  padding-left: 2px;
}

#chooseCustomer .customer-details.edit-details-modal.contract-details-field {
  height: 43px;
}

#chooseReseller .search-icon,
#chooseCustomer .search-icon {
  padding-top: 2px;
  padding-bottom: 1px;
}

#chooseCustomer .search-icon .bg-white.edit-details-modal {
  padding-bottom: 5px !important;
}

#chooseReseller .search-icon .bg-white,
#chooseCustomer .search-icon .bg-white {
  padding-top: 5px !important;
  padding-bottom: 11px !important;
  border-radius: 20px;
  height: 93%;
}

#chooseReseller .react-autosuggest__suggestions-container--open {
  left: -26px;
  right: -81px;
  top: 39px;
  padding: 0;
  /* border-radius: inherit; */
  background-color: #ffffff;
  border-radius: 4px;
  width: 334px;
  margin-top: 1px;
  padding-top: 0px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.76);
  font-size: 14px;
  letter-spacing: 0.47px;
}
#chooseCustomer .react-autosuggest__suggestions-container--open {
  left: -26px;
  right: -81px;
  top: 39px;
  padding: 0;
  /* border-radius: inherit; */
  background-color: #ffffff;
  border-radius: 4px;
  width: 334px;
  margin-top: 1px;
  padding-top: 0px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.76);
  font-size: 14px;
  letter-spacing: 0.47px;
}
#chooseReseller .react-autosuggest__suggestions:hover,
#chooseCustomer .react-autosuggest__suggestions:hover {
  background-color: #eeeeee;
}
#customerModal .react-autosuggest__suggestions-container--open {
  left: -21px;
  right: -66px;
  top: 34px;
  border-radius: 4px;
  margin-top: 4px;
  padding-top: 0px;
  padding-left: -55px;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.76);
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.47px;
}
#customerModal .react-autosuggest__suggestion:hover {
  background-color: #eeeeee;
}
/* #chooseReseller .react-autosuggest__suggestions-container--open,
#chooseCustomer .react-autosuggest__suggestions-container--open {
  right: -80px;
  left: -24px;
  top: 28px;
  background: #f7f7f7;
  border-top: 0;
  padding-top: 21px;
} */

#customerDetails .react-autosuggest__suggestions-container--open {
  padding-top: 10px;
}

/* #chooseReseller .react-autosuggest__input,
#chooseCustomer .react-autosuggest__input {
  position: absolute;
  z-index: 4;
  margin-left: 8px;
  border-radius: 25px;
  padding-left: 4px;
} */

.z-index-4 {
  z-index: 4 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.bg-color-inherit {
  background-color: inherit;
}

.bg-disabled {
  background-color: #dedede;
}

.loading-bg-height {
  min-height: 70vh;
}

.rotate-0deg {
  transform: rotate(0deg);
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.rotate-180deg {
  transform: rotate(180deg);
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.rotate-270deg {
  transform: rotate(270deg);
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.rotate-90deg {
  transform: rotate(90deg);
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.card-rounded {
  border-radius: 4px !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.card {
  border-radius: 4px !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.box-shadow-none .card {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.accordion .row:nth-of-type(odd) .col .table-row .rounded-color a span {
  background-color: transparent !important;
}

.contracts-accordion.accordion .row:nth-of-type(odd) .col .table-row .rounded-color a span {
  background-color: transparent !important;
}

.accordion .row .col .table-row .rounded-color a span {
  background-color: transparent;
}

.signin-input {
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 32px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.signin-input:focus {
  border-color: #1d1d1d;
  border-bottom-width: 0px;
}

.text-area {
  height: 165px;
  transition: all 0.4s ease;
  /*overflow: hidden;*/
}

.textarea-height {
  height: 200px;
}

.content-reseller-height {
  min-height: 55px;
}

.footer-height {
  min-height: calc(100vh - 313px);
}

.footer-height-reseller {
  min-height: calc(100vh - 450px);
}

.list-mb-50 {
  margin-bottom: 50px;
}

.filled-form p {
  font-size: 14px;
}

/* .card-details-filled {
  background-color: #dedede;
} */

.services-rounded {
  border-radius: 10px !important;
  /* padding-bottom: 0 !important; */
}

.bg-services-child {
  background-color: #f9f9f9;
}

.p-extra-services {
  padding: 0px 40px 0px 40px;
}

.bg-extra-services-greeen {
  background-color: rgba(0, 108, 98, 0.15);
  border-radius: 30px;
}
.bg-extra-services-grey {
  background-color: rgba(98, 110, 109, 0.15);
  border-radius: 30px;
}
.bg-extra-services-new {
  background-color: rgba(98, 110, 109, 0.15);
}
.font-extra-services,
.contracts-modal .font-extra-services {
  font-size: 14px !important;
}

.bg-services {
  background-color: #fbfafa;
  border-radius: 30px;
}

.collapsed .arrow-expand {
  transform: rotate(0deg);
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.arrow-expand {
  transform: rotate(180deg);
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.react-autosuggest__input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #4a4a4a;
}
.react-autosuggest__input::-moz-placeholder {
  /* Firefox 19+ */
  color: #4a4a4a;
}
.react-autosuggest__input:-ms-input-placeholder {
  /* IE 10+ */
  color: #4a4a4a;
}
.react-autosuggest__input:-moz-placeholder {
  /* Firefox 18- */
  color: #4a4a4a;
}

.reseller-card--service {
  background: #fff;
  border-radius: 25px;
}
.card:first-child.box {
  z-index: inherit 0;
}

.box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  opacity: 1;
  z-index: 9;
}

.box {
  z-index: 0;
}

.radio-off {
  background-color: black;
  height: 20px;
  width: 20px;
  border-radius: 50px;
}

.radio-on {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border: 2px solid rgb(117, 116, 116);
}

.ri-table .table-row .thumbnail {
  overflow: hidden;
  position: relative;
  padding: 9px 7px;
}

.ri-table .table-row .rotate-180deg {
  background-color: #f2f2f2;
}

.ri-table .table-row .thumbnail:hover {
  opacity: 0.7;
  background-color: #f2f2f2;
}

.ri-table .table-row .thumbnail:hover::after {
  background: url(../assets/images/icons/icon-accordion-arrow-green.svg) no-repeat center center;
}

.dashbtn:hover {
  color: rgba(255, 255, 255, 0.75);
}
/*.footer-textdec:hover{
  text-decoration: underline;
}*/
.ri-btn {
  border: medium none;
  border-radius: 0;
  font-weight: normal;
  padding: 0.375rem 0.375rem 0;
  margin-bottom: -2px;
  transition: 0.15 ease-in-out;
  width: unset;
}
.ri-btn > span:hover {
  border-bottom: 3px solid rgba(255, 255, 255, 0.75);
  font-size: 32px;
}

footer .btn-link:hover,
.btn-link:focus {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  transition: 0.15 ease-in-out;
  opacity: 0.65;
}

.border-bottom-light {
  border-bottom: 1px solid #dcdcde;
}

.border-bottom-grey {
  border-bottom: 1px solid #c3c3c3;
}

.border-bottom-979797 {
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}

.bg-green {
  background-color: #f2f2f2;
}

.bg-green-1 {
  background-color: #6ec3a6;
}

.bg-white-transform {
  background-color: #ffffff;
  transition: all ease-in-out 0.5s;
}

.font-disabled {
  color: #828080;
  font-weight: 700;
}

.table-header-order {
  border-bottom: solid 1px #dcdcde;
}

/* .table-header {
  border-bottom: 1px solid #c3c3c3;
} */

.img-thumbnail {
  border-radius: 0% !important;
  background-color: #f2f2f2;
  border: 1px solid #d9dce6;
  max-width: 100%;
  height: 250px;
}

.imageCropped {
  width: 100%;
  height: 100%;
  /* padding-bottom: 100%; */
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  /* border-radius: 10px; */
  transition: transform 0.5s ease;
  width: 333px;
  height: 243px;
}

.imageCropped.carousel {
  min-height: 259px;
}

.bg-highlight {
  background-color: rgba(0, 108, 98, 0.23);
}

.services-child-pad-top {
  margin-top: -9px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.arrow-down-black {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);

  -webkit-transform: rotate(45deg);
  margin-left: 9px;
}
.arrow-down-disabled {
  border: solid #828080;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);

  -webkit-transform: rotate(45deg);
  margin-left: 5px;
}

.left-30 {
  left: 89px;
}

.tooltip-custom {
  position: absolute;
  display: inline-block;
  right: 36px;
  top: 15px;
}

.tooltip-custom .tooltip-content {
  visibility: visible;
  width: 168px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  bottom: -20%;
  left: 110%;
  opacity: 1;
  margin-right: 70px;
}

.tooltip-content::after {
  content: '';
  position: absolute;
  left: 0;
  top: 90%;
  width: 0;
  height: 0;
  border: 25px solid transparent;
  border-right-color: #555;
  border-left: 0;
  border-bottom: 0;
  margin-top: -30px;
  margin-left: -15px;
}

/* .tooltip-custom:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
} */

@keyframes tooltipAnimation {
  0% {
    opacity: 1;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

#tooltipContent {
  animation-name: tooltipAnimation;
  animation-duration: 9000ms;
  animation-fill-mode: forwards;
}

.ItemPriceEditable {
  -webkit-appearance: none;
  margin: 0;
  cursor: pointer;
  -moz-appearance: textfield;
}

/* .ItemPriceEditable:hover {
  color: #666;
} */

.languageSelector {
  background-color: #1d1d1d;
  border: 0;
  padding: 0px 5px;
  font-weight: normal;
  color: white;
}

.languageSelector:hover {
  transition: transform 0.3s ease-out;
  display: inline-block;
  transform: translate(0, -2px);
  cursor: pointer;
  color: #d8d8d8;
}

input:required {
  box-shadow: none;
}
input:invalid {
  box-shadow: 0 0 0 grey;
}

select:not([multiple]) {
  background-image: url('../assets/images/icons/icon-accordion-arrow-white.svg');
  padding-right: 15px;
}

.editImage {
  padding-left: 90px;
  margin-bottom: 30px;
}

.Dropzone {
  background-color: #ffffff03;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  z-index: 2;
  position: relative;
  width: 333px;
  height: 243px;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.Highlight {
  z-index: 10;
  background-color: rgba(2, 29, 26, 0.829) !important;
}

.file-upload {
  position: absolute;
  bottom: 135px;
  left: 65px;
  opacity: 0;
}

.overlayProductImage {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  /* padding-bottom: 100%; */
  background: rgba(0, 0, 0, 0);
  /* border-radius: 10px; */
  transition: transform 0.5s linear, background 0.3s ease;
}

.imageClass:hover .overlayProductImage {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  transform: scale(1.01);
}

.imageClass:hover .file-upload {
  opacity: 1;
}

.imageClass:hover .imageCropped {
  transform: scale(1.01);
}

.modal-backdrop {
  background-color: #1d1d1d47;
}

.btnBoot {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: color 0.15s;
  width: 180px;
  height: 70px;
}

.btn-outline-secondaryBoot {
  color: #6c757d;
  border-color: #6c757d;
  background-color: #e6e6e6;
}

.btn-outline-secondaryBoot:hover {
  color: white;
  background-color: #6c757d;
}

.imageUploadOverlayText {
  position: absolute;
  opacity: 0;
  color: white;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  z-index: 20;
  padding: 2.5rem;
}

.imageUploadOverlayTextDrag {
  position: absolute;
  color: white;
  font-size: 30px;
  left: 60px;
  text-transform: uppercase;
  z-index: 20;
}

.imageClass:hover .imageUploadOverlayText {
  opacity: 1;
}

.imageClass:hover .productImageDelete {
  display: contents;
}

.productImageIcons {
  position: absolute;
  z-index: 10;
  top: 0%;
  right: 8%;
  padding-top: 6px;
}

.productImageEditIcon {
  cursor: pointer;
  padding-left: 10px;
  transition: transform 0.3s ease;
}

.productImageEditIcon:hover {
  transform: translate(0, -2px);
}

.productImageDelete {
  display: none;
  cursor: pointer;
  padding-right: 5px;
}

.dark_loading_overlay {
  background: #1d1d1d !important;
  color: #fff;
}

.loading__overlay_white_wrapper > ._loading_overlay_overlay {
  background: #ffffff !important;
  z-index: 2;
}

._loading_overlay_overlay,
.confirmOrder_overlay {
  background: #f2f2f2 !important;
  color: #000 !important;
}
.confirmOrder_overlay {
  width: 110% !important;
  left: -10px !important;
  top: -10px !important;
}

.light_modal_overlay {
  background: #fff !important;
  color: #000 !important;
}

._loading_overlay_content {
  color: #000000 !important;
}

.dark-loading-overlay-overlay {
  background-color: #ffffff !important;
  color: #000000 !important;
  min-height: 55vh;
}

.dark-loading-overlay-spinner svg circle {
  stroke: #000000 !important;
}

/* Documents Upload Product page */

.documentUploadButton {
  padding: 15px;
  background: #006c62;
  display: table;
  color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
}

.documentsUpload {
  border-radius: 10px;
  background-color: #f2f2f2;
}

.product-brief-view {
  background-color: #eaecec;
  border-radius: 8px;
}

/* React Quill Text Editor Custom CSS */

.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
}

.ql-editor {
  min-height: 128px;
}
.ql-editor-change {
  max-height: none !important;
  min-height: 152px;
}

.ql-container {
  min-height: 10em;
  border-top: 0px;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #ffffff;
}
.ql-change-container {
  min-height: auto !important;
  border: none !important;
  background: #eaecec;
}

/* Background Transparent */

.bg-trans {
  background: transparent;
}
.ql-custom {
  content: 'Hello';
}
.product-body-full-width {
  height: 98%;
  width: 106%;
  background-color: #f2f2f2;
  margin-left: -47px;
  margin-top: -30px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 12px;
}

.product-body-full-width-non-selected {
  width: 106%;
}

.product-body-selected-padding {
  padding-left: 85px;
  padding-top: 70px;
  padding-bottom: 30px;
  padding-right: 0;
}

.item-selected {
  width: 106% !important;
  padding-right: 7px !important;
}

.product-header-border {
  border-radius: 25px;
}

.margin-left-neg-70 {
  margin-left: -70px;
}
.margin-left-neg-50 {
  margin-left: -50px;
}
.margin-left-neg-34 {
  margin-left: -34px;
}
.margin-left-neg-55 {
  margin-left: -55px;
}
.margin-left-neg-43 {
  margin-left: -43px;
}
.margin-left-neg-8 {
  margin-left: -8px;
}
.margin-top-neg-11 {
  margin-top: -11px;
}

.upload-file-btn {
  min-width: 170px !important;
}

.image-crop-no-image {
  width: 100%;
  height: 100%;
  /* padding-bottom: 100%; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* border-radius: 10px; */
  transition: transform 0.5s ease;
  width: 333px;
  height: 243px;
}
.image-no-crop-image {
  width: 600px;
  height: 600px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transition: transform 0.5s ease;
}
.postpone-delivery {
  margin-top: -35px;
  margin-bottom: 20px;
}

.react-datepicker__input-container {
  display: inherit;
  padding: 5px;
}

.react-datepicker__input-container > input {
  border: none;
  background-color: #fff;
  width: 100%;
  margin-left: 0.09rem;
}
.react-datepicker-wrapper {
  display: inherit;
}

.postpone-delivery-label {
  color: #6a6a6a;
  top: 0.2rem;
  font-size: 85%;
}

.info-icon-span {
  display: inline-block;
  vertical-align: middle;
}

.fa-md {
  font-size: 1.1rem !important;
}

.contract-details-head-block {
  background-color: #f8f8f8;
  border-radius: 15px;
  padding: 20px 59px;
}

.contract-details-tab-head {
  background-color: inherit !important;
}
.contract-details-tab-head:hover {
  background-color: inherit !important;
}

.contracts-action-button {
  background-color: rgba(0, 0, 0, 0.09);
  font-weight: 600;
  color: #232323;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  min-width: 118px;
  text-align: center;
  width: 172px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #d8d8d8;
  padding: 0.5rem !important;
  background-color: inherit !important;
  /* border: none !important; */
  border-bottom: solid 1px #d8d8d8 !important;
}
.contracts-action-button a,
.contracts-action-button span {
  width: 85px;
  height: 16px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1.27px;
  color: #000 !important;
  vertical-align: middle;
}
.contracts-action-button a,
.contracts-action-button span span {
  width: 85px;
  height: 16px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1.27px;
  color: #000 !important;
  vertical-align: middle;
}
.contracts-heading-action-button .contracts-action-button {
  background-color: #ffffff !important;
}
.contracts-action-add-service {
  color: #232323;
  /* background-color: rgba(0, 0, 0, 0.09) !important; */
}

.contracts-action-add-service a,
.contracts-action-add-service a:hover {
  color: #232323;
  text-decoration: none;
}

.contracts-action-terminate {
  color: #fff;
  font-weight: 600;
  padding: 5px 19px;
  background-color: #d98282;
}

.contracts-action-remove {
  color: #fff;
  border: 2px solid #d98282;
  font-weight: 600;
  padding: 2.5px 25px;
  background-color: #d98282;
}

.contracts-action-renew {
  color: #fff;
  background-color: #7ec3b4;
  padding: 4px 17px;
}

.contracts-action-renew.contracts-action-activateall {
  padding: 5px 19px;
}

.contracts-action-cancel {
  color: #d98282;
  background-color: transparent;
  border: 2px solid #d98282;
  padding: 3px 25px;
}
.contracts-action-edit {
  color: #7ec3b4;
  background-color: transparent;
  border: 2px solid #7ec3b4;
  padding: 3px 25px;
}
.contracts-action-select {
  border: 2px solid #7ec3b4;
  color: #7ec3b4;
  padding: 2.5px 25px;
  background: none;
}
.contracts-action-selected {
  border: 2px solid #7ec3b4;
  color: #fff;
  padding: 2.5px 25px;
  background: #7ec3b4;
}

.contract-service-card-button {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: #fafafa;
  margin-top: 16px;
  right: 4rem;
}

.serives-active-tick {
  background: #27cf7c;
  color: #fff;
  border-radius: 50%;
  font-size: 12px !important;
  padding: 0.28rem;
}
.serives-terminated-tick {
  background: #dc5b5b;
  color: #fff;
  border-radius: 50%;
  padding: 0.28rem;
  font-size: 12px !important;
  line-height: 0.8 !important;
  vertical-align: middle;
}

.add-service-icon {
  font-size: 1rem !important;
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 3px;
}
.add-service-icon-activateall {
  background-color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 50%;
  width: 21px;
  height: 21px;
  padding: 2px;
}

.add-service-icon-terminateall {
  color: rgba(0, 0, 0, 0.87);
  transform: rotate(45deg);
}

.service-detail-date {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  opacity: 0.55;
}

.contracts-text-fade {
  opacity: 0.55;
}

.last-bill-change {
  opacity: 1;
}
.choose-service-container {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  background: #f2f2f2;
}

.choose-service-price {
  position: absolute;
  right: 25px;
  top: 3rem;
}

@media (min-width: 576px) {
  .base-modal-all {
    max-width: 650px;
  }
}

.list-view-order-block {
  background: #fff;
  border-radius: 15px;
  padding: 15px;
  margin: -15px;
}

.brownish-grey span,
.brownish-grey-text {
  color: #6a6a6a;
}

.list-view-order-block .accordion > .row,
.list-view-order-block .accordion > .row .ri-list-row-details .row {
  margin: 0;
}

.pl-2rem {
  padding-left: 2rem;
}

.rounded-small {
  border-radius: 12px;
}

div .service-order-card-body {
  padding: 24px 32px;
  min-height: 100px;
}

.bg-grey {
  background: #fbfbfb;
}

.add-on-services {
  margin-top: -20px;
  border-left: 4px solid #ddd;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.service-holder .add-on-services:nth-child(2) {
  border-top: 1px solid #f2f2f2;
}
.service-holder .card:last-child .border-bottom-light {
  border: 0;
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 950px;
  }

  .modal-lg.edit-details-modal,
  .modal-lg.create-service-modal {
    max-width: 890px;
  }
}

.add-service-date .react-datepicker__input-container > input {
  border: 0;
  max-width: 173px;
}
.disabled-state {
  opacity: 0.4;
}

.item-details-total {
  font-size: 20px;
}

.item-details-total-price {
  color: #27cf7c;
}

.item-details-tot-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 2px solid #eee;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 0.55;
}
.category {
  width: 378px;
  height: 48px;
}
.category1 {
  width: 378px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #b0b2b9;
}
.status {
  width: 186px;
}
.addproduct-controls {
  width: 432px;
}
.status1 {
  width: 186px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #b0b2b9;
}
span.tab-space {
  padding-left: 5em;
}
.status2 {
  width: 378px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #b0b2b9;
}
.opt {
  padding: 8px;
  width: 40px;
}
.services .card-title .contract-service-title {
  position: relative;
  border-bottom: 0px;
  /* min-width: 135px; */
}

/* .contract-service-title:after {
  position: absolute;
  content: "";
  height: 5px;
  bottom: -3px;
  left: 0px;
  width: 100%;
  background: #000;
} */

.ri-tab-header .contract-details-tab-head.active:after {
  /* display: none; */
}

.ri-tab-header .contract-details-tab-head.active {
  /* border-bottom: 2px solid #009988 !important; */
  font-weight: 600 !important;
}
.tab-header .contract-details-tab-head.active:after {
  display: none;
}

.tab-header .contract-details-tab-head.active {
  border-bottom: 3px solid #009988 !important;
}
.border-radius-5px {
  border-radius: 5px;
}

.date-display {
  position: relative;
  pointer-events: none;
  border: 0;
  padding: 5px;
  border-radius: 5px;
}

.services .modal-apply-btn {
  font-size: 16px;
}

.date-display:disabled {
  background: none;
  opacity: 0.3;
}

.date-picker-holder {
  position: relative;
}

.date-picker-holder .react-datepicker__input-container input {
  border: none;
  padding: 0 8px;
}

.date-picker-holder .fa-calendar {
  position: absolute;
  bottom: 14px;
  cursor: pointer;
  right: 10px;
}

.contracts-modal.rounded {
  border-radius: 15px !important;
}

.contracts-modal .close {
  margin: 0.1rem 1rem;
  font-weight: normal;
  color: #686868;
  opacity: 1;
  font-size: 1.8rem;
  padding: 0 !important;
}

.contracts-modal .close:focus,
.edit-details-modal .close:focus,
.create-service-modal .close:focus {
  outline: none;
}

.contracts-modal .modal-apply-btn {
  padding: 13px 0;
}

#add-new-services .price-edit-holder,
#add-new-services .price-edit-holder input {
  text-align: right;
  background: none;
  font-weight: bold;
  font-size: 20px;
}

.border-dark-grey {
  border-color: rgba(29, 29, 29, 0.4) !important;
}

.black {
  color: #000000;
}
.body-color {
  color: rgba(0, 0, 0, 0.87);
}
.date-box {
  border: 1px solid #b0b2b9;
  border-radius: 4px;
}
.btn-md {
  min-width: 150px;
}
.form-control-placeholder-customer-suggestion {
  color: #6a6a6a;
  position: absolute;
  top: -0.6rem;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  font-size: 14px;
  z-index: 100;
}

.customer-suggest-error {
  position: absolute;
  top: 44px;
}

.contract-detail-suggest {
  border-bottom: 1px solid #6a6a6a;
}

.contract-detail-suggest .react-autosuggest__input {
  margin-left: 0 !important;
}

.contract-detail-suggest .react-autosuggest__input::placeholder {
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 400;
}

.contract-detail-suggest .react-autosuggest__suggestions-container--open {
  left: 0px !important;
  top: 0px;
  background-color: #ffffff;
  border: 1px solid #ffffff !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  height: inherit;
  max-height: 300px !important;
}
.parent-not-selected {
  opacity: 0.5;
  pointer-events: none;
}

div .parent-service__control {
  border: none;
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
  outline: none !important;
  cursor: pointer;
}

div .parent-service__control--is-focused {
  border-color: inherit !important;
  box-shadow: inherit;
}
.fade-contracts {
  background: #15151575;
}
.fade-products {
  background: rgb(0, 0, 0, 0.4);
}
.fade-contracts > .modal-dialog {
  top: 10.56rem;
}
.contract-details-field {
  padding-top: 15px !important;
  padding-left: 1px !important;
}

.arrow-down,
.arrow-up {
  position: relative;
}

.arrow-down:after {
  content: '↓';
}
.arrow-up:after {
  content: '↑';
}

.arrow-down:after,
.arrow-up:after {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -3px;
  left: 0;
}

.bg-transparent {
  background: transparent !important;
}

.w-min-25 {
  min-width: 25%;
}

.strike-through {
  text-decoration: line-through;
}

.h-100 {
  height: 100%;
}

.orders-list-scroll {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
}

.orders-list-scroll::-webkit-scrollbar {
  width: 0.3em;
}

.orders-list-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
}

.orders-list-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 50px;
}
.new-product-btn {
  font-weight: 600;
  min-width: 180px;
  width: auto;
  height: 44px;
  border-radius: 4px;
  border: solid 1px #d8d8d8;
  cursor: pointer;
}
.new-user-btn {
  font-weight: 600;
  width: 233px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px #d8d8d8;
  cursor: pointer;
}
.product-heading {
  font-size: 16px;
  font-weight: 600;
  color: #3a3b3f;
  line-height: normal;
  letter-spacing: normal;
}
.product-quill {
  width: 634px;
  border-radius: 6px;
}
.product-quill:hover {
  border: 1px solid rgb(0, 0, 0, 0.87);
  border-radius: 6px;
}
.product-quill:active {
  border: 1px solid rgb(0, 0, 0, 0.87);
  border-radius: 6px;
}
.product-quill:focus {
  border: 1px solid rgb(0, 0, 0, 0.87);
  border-radius: 6px;
}
.pcolor {
  color: #3a3b3f;
}
.product-bar-sm {
  width: 186px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #b0b2b9;
}
.product-bar {
  width: 432px;
  height: 48px;
  border-radius: 4px;
  border-color: #b0b2b9;
}
.filter-button {
  width: 144px;
  background: #00897b;
  color: #fff;
  border-radius: 7px;
  padding: 13.5px 0px;
}

.filter-button-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.filter-pop-over-container {
  position: absolute;
  z-index: 2;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 27.1px;
  width: 1000px;
}

.new-order-filter-head {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 17px;
}

.check-box-selected {
  width: 18px;
  height: 18px;
  /* border-radius: 6px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00897b;
}

.check-box-selected:hover {
  cursor: pointer;
}

.new-order-filter-item {
  font-size: 14px;
  color: #000;
}

.check-box-deselect {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid 2px rgba(0, 0, 0, 0.54);
  display: flex;
}

.check-box-deselect:hover {
  cursor: pointer;
}

.clear-filter-text {
  background: transparent;
  border: none;
  color: #e06d6d;
}

.clear-filter-text:hover {
  cursor: pointer;
}

.action-text-filter {
  background: transparent;
  border: none;
  color: #00897b;
}

.action-text-filter:hover {
  cursor: pointer;
}

.filter-check-tick-icon {
  width: 12px;
}

.range-filter-track {
  color: #00897b;
}

.order-filter-class > .rc-slider-track {
  background-color: #00897b;
}

.order-filter-class > .rc-slider-handle {
  background-color: #fff;
  border: solid 2px rgba(0, 0, 0, 0.16);
}

.rc-slider-tooltip {
  z-index: 1000;
}

div .rc-slider-tooltip-inner {
  background-color: inherit;
  border: none;
  box-shadow: none;
  color: #00897b;
  font-size: 16px;
  font-weight: 600;
}

.range-filter-container {
  height: 73px;
  align-items: center;
}

.filter-cust-letter {
  font-size: 18px;
  color: #d3d3d3;
  margin-left: 10px;
  cursor: pointer;
}

.all-order-filter-ctn {
  min-width: 800px;
}

.filter-date-picker > .react-datepicker-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.2);

  border-radius: 4px;
}

.filter-date-picker > div > .react-datepicker__input-container {
  width: 122px;
}

.filter-date-picker > div > .react-datepicker__input-container > input {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}

.filter-date-picker-icon {
  margin-bottom: -5px;
}

.filter-cust-highlight {
  color: #000;
}

.head-caps-button {
  display: flex;
  border-radius: 7px;
  border: solid 1px #d0d0d0;
  padding: 3px 18px 3px 18px;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 5px;
  font-size: 14px;
}

.cross-icon-head-cap {
  fill: #d0d0d0;
  width: 15px;
  cursor: pointer;
  height: 15px;

  margin-left: 13px;
}

.cancel-text-color {
  color: #000;
}

.selected-products-text {
  color: #6a6a6a;
}

.mb-2-25 {
  margin-bottom: 2.25rem;
}

.selected-products-head-text {
  font-size: 16;
  color: #6a6a6a;
  font-weight: 400;
}

.selected-products-header {
  margin-bottom: 36px;
}

.selected-product-list {
  padding-left: 60px;
  border-bottom: 3px solid #f2f2f2;

  margin-bottom: 21px;
}

.selected-product-list-elements {
  margin-bottom: 5px;
}

.product-list-view-container {
  margin-bottom: 45px;
}

.selected-product-btn-price {
  padding: 1rem 1rem;
}

.selected-qty-total {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 600;
}

.product-selection-button-container {
  justify-content: center;
  display: flex;
}

.prepare-order-next-btn {
  display: flex;
  background-color: #000;
  width: 90%;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
}
.tab-tablist-custom {
  border-bottom: 2px solid #d8d8d8;
}
.tab-tablist-custom > .redigere {
  border-bottom: none;
}
.redigere {
  width: 60px;
  height: 20px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #1d1d1d;
}
.tab-container-custom > .react-tabs-1 {
  /* margin-left: 63px; */
  font-size: 16px;
  font-weight: 300;
}

.tab-container-custom > .react-tabs-2 {
  margin-left: 28px;
  color: #707070;
  font-size: 14px;
}

.tab-container-custom > .react-tabs-3 {
  font-size: 16px;
  font-weight: 400;
  color: #242f33;
  /* margin-left: 65px; */
  width: 88%;
}
a .tab-tablist-custom > .redigere {
  border-bottom: none;
}
.tab-tablist-custom {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 34.8px; */
  width: 100%;
  /* margin-left: auto; */
}

.tab-title-custom {
  border: none;
  background: inherit;
  font-size: 14px;
  color: #000;
  list-style: none;
  cursor: pointer;
}

.tab-selected-custom {
  background: inherit;
  list-style: none;
  border-bottom: 3px solid #009988;
  padding-bottom: 4px;
  margin-bottom: -2px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.44px;
  color: #000000;
}

.edit-button-feature {
  position: absolute;
  right: -5px;
  cursor: pointer;
  display: none;
}

.product-feature-container:hover .edit-button-feature {
  display: inline-block;
}

#service-card .service-price-big {
  font-size: 2.875rem;
  line-height: 1;
}

#service-card .service-desc-list {
  padding-left: 0px;
  font-size: 0.875rem;
  color: #000;
  list-style: none;
  line-height: 1.14;
  letter-spacing: 0.47px;
}

#service-card .service-desc-list li {
  position: relative;
  margin-bottom: 0;
  padding-left: 18px;
  margin-bottom: 8px;
}

#service-card .service-desc-list li:last-child {
  margin-bottom: 0px;
}
/* 
#service-card .service-desc-list li:last-child:before {
  background: #f2c739;
} */

#service-card .service-desc-list li:before {
  content: '';
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 20px;
  top: 4px;
  left: 0;
  background: #000000;
}

.product-feature-editable {
  background: inherit;
  border: none;
  width: 100%;
}

.edit-icon-product-feature {
  width: 17px;
  height: 17px;
}

.product-feature-container {
  padding-bottom: 3px;
  margin-bottom: 0px;
}

.file-icon-products {
  width: 13.1px;
  height: 16px;
}

.file-upload-product-head {
  margin-bottom: 24px;
}

.margin-left-12 {
  margin-left: 12px !important;
}

.file-icon-products-container {
  margin-left: -10px;
}

.product-file-add-btn {
  background: #00897b;
  color: #ffffff;
  width: 100%;
  padding: 16px 0px;
}

.product-file-add-container {
  margin-top: 90px;
}

.pl-43 {
  padding-left: 43px;
}

.select-btn-primary {
  max-width: 266px;
  border-radius: 7px;
  margin: auto;
  color: #00897b;
  font-weight: 700;
  border-width: 2px;
  border-color: #00897b;
}

.select-btn-primary.show-active {
  background: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  pointer-events: none;
}

.select-btn-primary.selected {
  background: #00897b;
  color: #fff;
}

.select-btn-primary:hover {
  background: #00897b;
  border-color: #00897b;
}

.search-products-component > div > .react-autosuggest__input {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background: #f7f7f7;
  color: #6a6a6a;
}

.search-products-component .react-autosuggest__container {
  height: 100%;
  margin-left: 30px;
}

.search-products-container {
  background: #f7f7f7;
  height: 100%;
}

.bg-grey-1 {
  background: #f7f7f7;
}

.selected-product-btn-price {
  color: #6a6a6a;
}

.child-services {
  color: #000;
}

.child-services:not(.parent-selected) {
  pointer-events: none;
  color: #b9b9b9;
}

.select-btn-primary:active {
  background-color: #00897b !important;
  border-color: #00897b !important;
}

.child-services:not(.parent-selected) .select-btn-primary {
  color: #b9b9b9;
  border-color: #b9b9b9;
}

.child-services .service-total-text {
  font-size: 15px;
}

.child-services .service-total {
  font-size: 45px;
}

#service-card {
  margin: auto;
}

#service-card .card-rounded {
  border: 2px solid #fff;
  height: 100%;
  /* max-width: 487px; */
}
/* 
#service-card .border-bottom-green {
  border-bottom: 2px solid;
} */

#service-card .card-selected .green {
  color: #009988;
}
/* #service-card .border-bottom-green
#service-card .card-selected {
  border-color: #009988;
} */

.pending-terminated-subscription {
  border: none !important;
  border-radius: 4px !important;

  box-shadow: none !important;
}

.pending-termination-border {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#service-card .service-child-name {
  line-height: 0.8;
}

#service-card .active-service {
  background: #00897b;
  color: #fff;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

#service-card .already-subscribed .read-more.show {
  top: -17px;
}

#service-card .already-subscribed .read-more.show:not(.parent) {
  top: 0px;
  min-height: 315px;
}

#service-card .active-service .min-height-50 {
  min-height: 56px;
}

#service-card .active-service .read-more.show {
  color: #000;
  padding: 20px;
  border-radius: 10px;
}

#service-card .active-service .service-desc-list li {
  color: #ffff;
}

#service-card .active-service .grey {
  color: #fff;
}

.read-more {
  display: none;
}

.read-more.show {
  display: block;
  position: absolute;
  background: #fff;
  left: 0;
  z-index: 9;
  top: -27px;
  min-height: 335px;
}

.service-parent-holder .read-more.show {
  min-height: 305px;
}

.child-services .select-btn-primary,
.service-parent-holder .read-more.parent .read-more-content .select-btn-primary {
  max-width: 90px;
  min-height: 30px;
}

.read-more .read-more-content {
  border: 2px solid #00897b;
  border-radius: 10px;
  position: relative;
}

.read-more .read-more-content .read-more-close {
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
}

.service-parent-holder {
  position: relative;
}

.service-parent-holder .read-more.parent .read-more-content {
  border: none;
}

.service-parent-holder .read-more .read-more-content .read-more-close {
  right: 0px;
  top: 0px;
}
.product-select-icon {
  padding-top: 3px;
  position: absolute;
  left: 10px;
  top: 0;
}

.product-item-select-style {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: solid 2px #cccccc !important;
  cursor: pointer;
}

.search-input-container > .form-control,
.search-input-container > .input-group-append > button {
  background: #f7f7f7 !important;
}

.card-confirm-order {
  border-radius: 15px !important;
}

.badge-color-custom {
  background-color: #00897b;
}

.confirm-order-total {
  padding: 18px 24px 32px 24px;
}

.text-color-d5d5d5 {
  color: #d5d5d5;
}

.confirm-order-selected-checkbox > span {
  margin-left: auto;
}

.border-radius-7 {
  border-radius: 7px;
}

.select-customer-container {
  width: 480px;
}

.btn-light-green {
  background-color: #00897b;
  border-radius: 7px;
  min-width: inherit !important;
  border: none;
  color: #ffffff;
}

/* New Design For Suggestion Input Container */

.search-input-container-customer > .input-group,
.search-input-container-customer
  > .input-group
  > .form-control
  > .react-autosuggest__container
  > .react-autosuggest__input,
.search-input-container-customer
  > .input-group
  > .form-control
  > .react-autosuggest__container
  > .react-autosuggest__input::placeholder,
.search-input-container-customer > .input-group > .form-control,
div .search-input-container-customer > .input-group > .input-group-append > button {
  background: #f2f2f2 !important;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
}
.search-input-container-customer > .input-group {
  height: 100%;
  background: #f2f2f2 !important;
  border: 1px solid #dbdbdb;
}

.search-input-container-customer > #search-box {
  border: none !important;
}

.search-input-container-customer > .input-group > .form-control {
  height: 100%;
  border-bottom-right-radius: 0;
  border-bottom: none;
  padding: 0.25rem 1.6rem;
  border-radius: inherit;
  background-color: #f2f2f2 !important;
}

.search-input-container-customer {
  border: 1px solid #f1f1f1;
}

.search-input-container-confirm-order {
  border-radius: 4px;
  /* border: solid 2px #b0b2b9 !important; */
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.search-input-container-confirm-order > .input-group,
.search-input-container-confirm-order
  > .input-group
  > .form-control
  > .react-autosuggest__container
  > .react-autosuggest__input,
.search-input-container-confirm-order
  > .input-group
  > .form-control
  > .react-autosuggest__container
  > .react-autosuggest__input::placeholder,
.search-input-container-confirm-order > .input-group > .form-control,
div .search-input-container-confirm-order > .input-group > .input-group-append > button {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 16px !important;
}
.search-input-container-confirm-order > .input-group {
  height: 100%;
  background: #ffffff !important;
  /* border: 1px solid #b0b2b9; */
}

.search-input-container-confirm-order > #search-box {
  border: none !important;
}

.search-input-container-confirm-order > .input-group > .form-control {
  height: 100%;
  border-bottom-right-radius: 0;
  border-bottom: none;
  padding: 0.25rem 0.8rem;
  border-radius: inherit;
  background-color: #ffffff !important;
}

.search-input-container-confirm-order {
  border: 1px solid #b0b2b9;
}
.price-edit {
  border: 1px solid #b0b2b9 !important;
  width: 91px;
  height: 30px;
  border-radius: 4px;
}
div .search-input-container-confirm-order > div > div > div > input {
  background-color: inherit !important;
}
.search-input-container-confirm-order > div > div > div > div {
  top: 42px;
  left: -16px;
  right: -70px;
  border-radius: 4px !important;
}

.form-control:focus {
  border: 1px solid #000 !important;
}

.bg-stone-grey {
  background: #b9b9b9;
}

.form-control ~ .form-control-placeholder {
  color: #4a4a4a !important;
  line-height: 24px;
}
.form-control.focus + .form-control-placeholder,
.form-control.not-empty + .form-control-placeholder {
  color: #6a6a6a;
}

.form-control .focus {
  color: #6a6a6a;
}

.form-control {
  border-bottom: 1px solid #8d8d8d;
}

.form-control.focus + .form-control-placeholder,
.form-control.not-empty + .form-control-placeholder {
  transform: translate(-5px, -32px);
  color: #3a3b3f !important;
}

.input-city-disabled > div > input {
  border: none;
}

.input-city-disabled > input:disabled {
  border: none;
  color: #000000;
}

.suggest-next-btn {
  border-top-right-radius: 38px;
  border-bottom-right-radius: 38px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #1c1c1c;
}

.border-radius-38 {
  border-radius: 38px;
}

.input-search-container > .react-autosuggest__container > .react-autosuggest__suggestions-container {
  top: 35px !important;
  right: -58px !important;
}

.edit-details-modal {
  border-bottom: 1px solid #000;
}
.disable-card {
  opacity: 0.3;
  pointer-events: none;
}

.button-for-loader > div {
  margin: auto;
}


.no-gutters {
    margin-right: 0;
    margin-left: 0;
 }
 .no-gutters > .col,
.no-gutters > [class*="col-"] {
   padding-right: 4px;
   padding-left: 5px;
}

.batch-button {
  padding: 8.5px 0px !important;
  width: auto;
  height: 44px;
  border-radius: 4px;
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
}
.batch-button span {
  width: auto;
  height: 16px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1.27px;

  color: #000000;
}

.filter-pop-over-container-orders > .filter-pop-over-container {
  margin-top: -1%;
  margin-left: 10%;
}

/* .filter-pop-over-container-orders > .filter-buttons-tabs {
  margin-top: -1%;
} */

.filter-pop-over-container-products > .filter-pop-over-container {
  margin-top: -0.5%;
  /* margin-left: 10%; */
}
/* .filter-pop-over-container-products > .filter-buttons-tabs {
  margin-top: -1.9%;
} */

.search-bar-width {
  max-width: 32.5%;
}

.filter-pop-over-container-resellers > .filter-pop-over-container {
  margin-top: -2.5%;
  margin-left: 10%;
  width: 870px;
}

.filter-pop-over-container-resellers .filter-data-selected {
  margin-top: 0%;
}

.filter-data-selected > .filter-pop-over-container {
  margin-top: -0.5%;
}

.filter-pop-over-container-users > .filter-pop-over-container {
  margin-top: -2.5%;
  margin-left: 10%;
  width: 920px;
}

.filter-pop-over-container-users .filter-data-selected {
  margin-top: 0%;
}

.select-icon-holder {
  position: relative;
}
.abonement-aktiv {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.75;
  letter-spacing: 0.51px;
  color: #009e94;
}
.text-black {
  color: #000000 !important;
}
.color-red {
  color: #ff6858;
}
.tick {
  width: 16px;
  height: 16px;
  object-fit: contain;
  color: #009e94;
  border: solid 1px #979797;
}
.confirm-order-terms-condition {
  max-width: 57%;
}

.br-7 {
  border-radius: 7px;
}

.br-4 {
  border-radius: 4px;
}

.btn-calc {
  width: 30px;
  height: 30px;
  border-radius: 0;
  margin-bottom: 3px;
}

.input-group > .input-element-search,
.input-group > .input-group-append > button {
  height: 100%;
  background-color: #f2f2f2 !important;
}

.btn-grey-light {
  background-color: #f2f2f2;
  border: 2px solid #ededed;
  padding: 0.6rem 0.75rem;
  font-weight: normal;
}

.search-input-container {
  border: 2px solid #ededed;
}

.br-2 {
  border-radius: 2px;
}

/* .accordion .row .col .table-row .rounded-color a span {
  background: url(../assets/images/icons/icon-accordion-arrow-black.svg);
} */

.w-180-max {
  max-width: 180px;
}

.w-186-max {
  max-width: 186px;
}

.w-1100-min {
  width: 1132px;
}

.w-180-min {
  min-width: 180px;
}

.w-107-max {
  max-width: 107px;
}

.w-207-min {
  min-width: 207px;
}

.w-172-max {
  max-width: 172px;
}
.w-600-max {
  max-width: 600px;
}
.search-input-container > .form-control,
.search-input-container > .input-group-append > button {
  height: 44px;
}

.border-bottom-dark {
  border-bottom: 1px solid #666666;
}

.list-view-card-body {
  padding: 24px 32px;
  min-height: 200px !important;
}

.order-body-1-col {
  max-width: 23.5%;
}
.order-body-2-col {
  max-width: 26%;
}

.w-192-max {
  max-width: 192px;
}

.w-200-max {
  max-width: 200px;
}
.w-280-max {
  max-width: 280px;
}

.fs-14 {
  font-size: 14px;
}

.fs-59 {
  font-size: 59.7px;
}

.page-link:hover {
  background-color: transparent;
}

ul.pagination {
  margin: 0px auto !important;
}

.va-middle {
  vertical-align: middle;
}

.common-search-field {
  height: 100%;
  background-color: #f2f2f2 !important;
  flex: 1 1 auto;
  border: 0 !important;
  color: #1d1d1d;
  font-size: 14px;
}
.search-field {
  height: 100%;
  background-color: #ffffff !important;
  flex: 1 1 auto;
  border: 0 !important;
  color: #1d1d1d;
  font-size: 14px;
}

.input-value-button {
  border: 3px solid #f2f2f2 !important;
  border-radius: 4px;
}

.bg-white-1 {
  background-color: #f2f2f2;
}
.bg-white-3 {
  background-color: #f8f8f8;
}

.product-sub-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.color-1d1d1d {
  color: #1d1d1d !important;
}

.color-474747 {
  color: #474747 !important;
}

.color-59aa46 {
  color: #59aa46 !important;
}
.color-ff5252 {
  color: #ff5252 !important;
}
.bg-ff5252 {
  background-color: #ff5252 !important;
}

.color-242f33 {
  color: #242f33 !important;
}

.btn-dark {
  background-color: #000000;
  border: none;
  color: #ffffff;
}

.btn-dark:disabled {
  background-color: #7f7f7f !important;
}

.web-kit-none {
  -webkit-appearance: none;
}

.btn-subscription-parent {
  min-width: 163px;
  min-height: 42px;
}

.btn-subscription-child {
  min-width: 100px;
}

.list-view-upgrade-subscription {
  padding: 0px 15px 0px 15px;
}

.text-white {
  color: #ffffff;
}

/* #service-card .text-white li:before {
  background-color: #ffffff;
} */

.subscription-child-btn-col {
  max-width: 109px;
}

.b-width-1 {
  border-width: 1px;
}

.b-width-1:hover {
  border-width: 1px;
}

.bg-grey-2 {
  background-color: #dbdbdb;
}

.counter-change-container {
  width: 102px;
  height: 32px;
  border: 1px solid #ededed !important;
}

.pop-up-info-service {
  position: absolute;
  padding: 24px 37px 32px 32px;
  left: 20px;
  top: 20px;
  line-height: 24px;
  letter-spacing: 0.54px;
  min-width: 619px;
}
.pop-up-info-parent-service {
  left: 28px;
}

.bg-white-2 {
  background-color: #fafafa;
}

.select-cust-search-width {
  max-width: 378px;
}
.select-cust-search-width-new-order {
  min-width: 334px;
  max-width: 334px;
}

.input-common {
  border: 1px solid #b0b2b9;
  border-radius: 4px;
  padding-top: 15px !important;
  padding-bottom: 30px !important;
  color: #242f33;
}
.input-common-placeholder {
  color: #4a4a4a;
}
.input-common.not-empty {
  padding-top: 21px !important;
  padding-bottom: 24px !important;
}

.form-control.focus + .form-control-placeholder,
.form-control.not-empty + .form-control-placeholder {
  top: 1.2rem;
}

.form-control-placeholder {
  top: 0.7rem;
}
.common-input-container {
  width: 378px;
}

.checkout-next-btn-size {
  min-width: 172px;
}
.new-sub-next {
  width: 172px;
}
.btn-next:disabled {
  opacity: 0.5;
  border-radius: 4px;
  background-color: #009688;
}
.btn-next:hover {
  border-radius: 4px;
  background-color: #00897b;
}
.btn-next {
  border-radius: 4px;
  height: 48px;
  background-color: #009688;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1.27px;
  color: #ffffff;
}
.card-badge {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: 20px;
  padding: 0;
  width: 20px;
}

.text-grey {
  color: #979797;
}

.line-height-20 {
  line-height: 20px;
}
.line-height-24 {
  line-height: 24px;
}
.line-height-114 {
  line-height: 1.14;
}
.login-page_wrapper {
  height: 100%;
  width: 100%;
  background: url(../assets/images/login-bg.png);
  display: flex;
  align-items: center;
}

.login-page_wrapper ~ .header {
  display: none;
}

.fs-60 {
  font-size: 60px;
}

.py-0-55 {
  padding-bottom: 0.55rem !important;
  padding-top: 0.55rem !important;
}

.w-378-max {
  max-width: 378px;
}

.w-345-max {
  max-width: 345px;
}
.w-619-max {
  max-width: 619px;
}

.h-44 {
  height: 44px;
}
.h-48 {
  height: 48px;
}
.h-225-max {
  max-height: 255px;
}
.border-2-ededed {
  border: 2px solid #ededed;
}
.vendor-color {
  background-color: #ffffff;
  color: #000000;
}
.border-vendor {
  width: 412px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #b0b2b9;
  background-color: #ffffff;
}
#selectedVendor .border-vendor {
  padding-top: 12px;
}
.searchbox {
  width: 378px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #b0b2b9;
}
.ls-0-25 {
  letter-spacing: 0.25px;
}
.ls-0-3 {
  letter-spacing: 0.3px;
}
.ls-1-27 {
  letter-spacing: 1.27px;
}
.filter-btn-text {
  line-height: 2;
  letter-spacing: 0.44px;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}

.text-black-1 {
  color: rgba(0, 0, 0, 0.87) !important;
}

.w-45-per-max {
  max-width: 45%;
}

.ls-0-44 {
  letter-spacing: 0.44px;
}
.ls-0-47 {
  letter-spacing: 0.47px;
}
.ls-0-01 {
  letter-spacing: 0.1px;
}
.ls-normal {
  letter-spacing: normal;
}
.check-box-radio {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.87);
  border-radius: 50%;
  cursor: pointer;
}
.check-box-radio-selected {
  width: 20px;
  height: 20px;
  border: 2px solid #009988;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.check-box-radio-selected > span {
  width: 10px;
  height: 10px;
  background-color: #009988;
  position: absolute;
  border-radius: 50%;
  top: 20%;
  left: 20%;
}

.select-reseller-label-confirm-order {
  position: absolute;
  top: -12px;

  left: 30px;
  background-color: #fff;
  font-size: 14px;
  color: #3a3b3f;
}

.arrow-down-sort {
  transform: rotate(270deg);
  margin-left: 11px;
}

.arrow-up-sort {
  transform: rotate(90deg);
  margin-left: 11px;
}

.users-arrow-up-sort {
  transform: rotate(0deg);
}

.users-arrow-down-sort {
  transform: rotate(180deg);
}

.postal-input-width .common-input-container {
  width: 100%;
}

.min-width-45-per {
  /* min-width: 44%; */
  width: 481px;
}
.min-width-50-per {
  /* max-width: 560px;
  min-width: 560px; */
  /* min-width: 56%; */
  width: 570px;
}

.loading-all-products > ._loading_overlay_overlay,
.loader-list-view-common > ._loading_overlay_overlay {
  width: 104%;
  left: -20px;
}

.common-search-field::-webkit-input-placeholder,
.common-search-field::-moz-placeholder,
.common-search-field::placeholder {
  color: rgba(0, 0, 0, 0.76);
  font-size: 14px;
}

.common-search-field::placeholder {
  color: rgba(0, 0, 0, 0.76);
  font-size: 14px;
}

.ri-table .table-header {
  color: rgba(0, 0, 0, 0.87);
}

.cursor-text {
  cursor: text;
}

.item-quantity input[type='number'] {
  border: none;
  padding: 0;
  border-bottom: 1px solid;
  text-align: center;
  width: 40px;
  background-color: #f2f2f2;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  margin: 0;
}

.item-quantity input[type='number']::-webkit-inner-spin-button,
.item-quantity input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  margin: 0;
}

.sign-in-text {
  line-height: 48px;
}

.w-20-per-max {
  max-width: 20% !important;
}
.w-237 {
  min-width: 237px !important;
}
.w-12-per-max {
  max-width: 12% !important;
}

.w-14-per-max {
  max-width: 14% !important;
}

.w-38-per-max {
  max-width: 38%;
}
.w-75-per-max {
  max-width: 75%;
}
.line-height-22 {
  line-height: 22px;
}
.line-height-28 {
  line-height: 28px;
}
.line-height-16 {
  line-height: 16px;
}
.line-height-1-43 {
  line-height: 1.43;
}
.line-height-normal {
  line-height: normal;
}
.tootltip-value-1 {
  position: absolute;
  bottom: -28px;
  left: 0;
  color: #00897b;
}

.tootltip-value-2 {
  position: absolute;
  right: 0;
  color: #00897b;
}

.opacity-0-8 {
  opacity: 0.8;
}
.opacity-0-7 {
  opacity: 0.7;
}

.react-tabs-2.react-tabs__tab-panel--selected,
.react-tabs-1.react-tabs__tab-panel--selected {
  margin-top: 0.5rem !important;
}
.mt-0-85 {
  margin-top: 0.85rem !important;
}
/* .accordion:last-child { 
  border-bottom: 1px solid #979797;
} */
.ri-table .accordion .row:last-child .table-row {
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}
.border-top-0 {
  border-top: 0 !important;
}

.min-height-44 {
  min-height: 44px;
}
.min-height-500 {
  min-height: 500px;
}
.select-customer-min-ht {
  min-height: 151px;
}

.py-0-78 {
  padding-bottom: 0.78rem !important;
  padding-top: 0.78rem !important;
}
.optional {
  font-size: 12px;
  color: #6c6d73;
  line-height: 1.33;
}
/* .searchvendor {
  width: 412px;
  height: 48px;
  border: solid 1px #b0b2b9;
  padding: 8px;
  background-color: #ffffff;
  z-index: 1;
} */
.searchvendor #search-box .react-autosuggest__suggestions-container--open {
  background: #ffffff;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  /* border: 1px solid #b0b2b9; */
  top: 40px;
  color: rgb(0, 0, 0, 0.87);
  left: -25px;
  width: 378px;
  border-radius: 2px;
  height: 180px;
  padding-top: 0px;
}
.carousel-wrap {
  width: 1000px;
  margin: auto;
  position: relative;
}
.owl-carousel .owl-nav {
  overflow: hidden;
  height: 0px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #2caae1;
}

.owl-carousel .item {
  text-align: center;
}
.owl-carousel .nav-btn {
  height: 47px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 250px !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide {
  background: no-repeat scroll 0 0;
  left: 105px;
}
.owl-carousel .next-slide {
  background: no-repeat scroll -24px 0px;
  right: 105px;
}
.fs-20 {
  font: normal normal normal 22px/1 FontAwesome;
}
.owl-carousel .prev-slide:hover {
  background-position: 0px -53px;
  color: #000;
}
.owl-carousel .next-slide:hover {
  background-position: -24px -53px;
  color: #000;
}

.owl-theme .owl-dots.sliderDots {
  margin-top: 0px;
}
.owl-theme .owl-dots.sliderDots button:focus {
  outline: none;
}
.owl-theme .owl-dots.sliderDots .owl-dot span {
  /* border: 1px solid #242f33; */
  /* background: transparent; */
  width: 8px;
  height: 8px;
}
.owl-theme .owl-dots.sliderDots .owl-dot.active span {
  border: 1px solid #242f33;
  background: #242f33 !important;
}
.sidemenu-text {
  text-transform: none;
}
.toastContainer {
  position: fixed;
  top: 30px;
  left: 45%;
  margin: 0.375rem;
  color: white;
  text-transform: capitalize;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: 0.125rem;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  padding: 0.6rem 2rem;
  font-size: 0.81rem;
  z-index: 999;
}
.toastContainer .close span {
  color: white;
}
.toastSuccess {
  background-color: #00c851 !important;
}
.toastError {
  background-color: #ff3547 !important;
}
.space-remove {
  line-height: 0;
}

.bg-light-dark {
  background-color: #242f33 !important;
}

#customerModal .common-input-container,
#editContractDetailsModal .common-input-container {
  width: 100%;
}

.common-select-container span.arrow {
  position: absolute;
  right: 10px;
  top: 10px;
}
.common-select-container span.arrow.rotate {
  transform: rotate(180deg);
}
.common-select-container .error-icon {
  position: absolute;
  right: 30px;
}
.dropdownList {
  position: absolute;
  z-index: 1;
  background: #ffff;
  width: 100%;
  border: 1px solid #b0b2b9;
  border-radius: 4px;
}

.dropdownList p {
  color: #1d1d1d;
}

.dropdownList p:hover {
  background-color: #eeeeee;

  color: #242f33;
}

.common-select-container .form-control:disabled,
.form-control[readonly] {
  opacity: 1;
}
.customer-reseller-selected {
  bottom: 5px;
}
.customer-reseller-label {
  top: -15px;
}
#customerModal #search-box.input-common {
  padding: 5px !important;
  padding-left: 1.25rem !important;
}
#customerModal #search-box.input-common .search-icon button {
  background: transparent !important;
}
#customerModal #search-box > div > div > input {
  font-size: 1rem;
  font-weight: 400;
  /* height: 2rem; */
  color: #4a4a4a;
}
.react-autosuggest__input::placeholder {
  color: #4a4a4a;
}
.dashboardLogo {
  width: 24px;
  height: 48px;
}
.optionalText {
  position: absolute;
  margin-top: -18px;
}
.headerIcon {
  width: 18px;
  height: 18px;
}

/* .SideMenuSpace {
  margin: 15px 32px 24px 0px;
  width: 1132px;
  padding-left: 32px;
  padding-top: 0;
}
.sidebar-mini .SideMenuSpace {
  margin: 15px 32px 24px 0px;
  padding-top: 0;
  width: 1166px;
  padding-left: 66px;
}
#products .SideMenuSpace,
#services .SideMenuSpace {
  margin: 15px 32px 24px 0;
  padding-top: 0;
  padding-left: 32px;
  width: 1132px;
}
.sidebar-mini #products .SideMenuSpace,
.sidebar-mini #services .SideMenuSpace {
  margin: 15px 32px 24px 0;
  padding-top: 0;
  padding-left: 66px;
  width: 1166px;
} */
.menuPopup .contracts-action-button {
  min-width: 200px;
  width: auto !important;
  font-size: 14px;
  height: auto !important;
  padding: 8px 0 !important;
  margin: 0px !important;
  border-radius: 0px;
  border: none;
  background-color: #ffffff;
}

/* Chart Custom CSS */

.card-white {
  min-width: 175px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
}

.tooltip-title {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  padding-bottom: 4px;
  padding-top: 4px;
  background-color: #ffffff;
  border-bottom-color: #dcdcde;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.registrations {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3794fc;
  margin-bottom: 4px;
}

.activations {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #009688;
  margin-bottom: 4px;
}

.terminations {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff5252;
  margin-bottom: 4px;
}

.tooltip-footer {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242f33;
  background-color: #ffffff;
  border-top-color: #dcdcde;
  border-top-width: 1px;
  border-top-style: solid;
  box-shadow: none;
}

#select-period {
  width: 200px !important;
}

#select-period .dropdown-menu {
  top: -3px !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
#select-period .dropdown-toggle::after {
  float: right !important;
  margin-right: 20px !important;
  margin-top: 0.6em !important;
}

.legend-revenue-marker {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #009688;
  border: none;
  margin-right: 3px;
}
.legend-activation-marker {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #242f33;
  border: none;
  margin-right: 3px;
}

.legend {
  position: relative;
  top: -42px;
  margin-bottom: -42px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
  font-family: OpanSans, Helvetica, Arial, sans-serif;
}
.right-2 {
  right: 2rem !important;
}
.react-multi-email {
  border: 1px solid #b0b2b9 !important;
  width: 500px;
  color: #09101d !important;
}
.react-multi-email.focused {
  border-color: #000000 !important;
}
.react-multi-email [data-tag] {
  color: #09101d !important;
  font-size: 14px;
  font-weight: 400 !important;
  border:solid 1px #e0e0e0 !important;
}